export const environment = {
  production: true,
  SCARF_ANALYTICS: false,
  // adminRoot: '/APP',
  // errorRoot: '/APP/blank-page',
  // loginURL: '/user/login',
  // live
  apiURL: "https://api-crm.bizsoft.app/api/",
  "api-CRM": "https://api-crm.bizsoft.app/api/",
  apiUrl: 'https://api-crm.bizsoft.app/api/',
  // local
  // apiURL: "https://localhost:44368/api/",
};
