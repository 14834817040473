import { Component, ElementRef, OnInit, ViewChild, Inject, } from '@angular/core';
import { SlidesOutputData } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ColorScssService } from '../../shared/service/color-scss.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';


@Component({
    moduleId: module.id,
    selector: 'accounting-web',
    templateUrl: 'accounting-web.component.html',
    styleUrls: ['accounting-web.component.scss']
})
export class AccountingWebComponent implements OnInit {
    public activeClass = 'home'

    constructor(private http: HttpClient, private FB: FormBuilder,
        public colorPicker: ColorScssService,
        @Inject(DOCUMENT) private document: Document,
        public breakpointObserver: BreakpointObserver,
        private route: ActivatedRoute,
        private title: Title,
    ) { }

    ngOnInit() {
        this.title.setTitle(this.route.snapshot.data['title']);
        this.colorPicker.setColorScheme('color-8');
        this.initializeForm();
    }


    active(val) {
        debugger
        this.activeClass = val;
        document.querySelector(`#${val}`).scrollIntoView({ behavior: 'smooth' });
    }
    /////////////////////////////////////////////////////////////////////////////////
    // Leather section start----------------------------------------------------- -->
    features = [
        {
            title: 'Financial',
            subTitle: 'Managemant',
            description: ' The ERP system features multi-company support, encompassing voucher and ledger management, including subsidiary ledgers, employee ledgers, and cost center management. It provides comprehensive financial reporting capabilities, including trial balance, balance sheet, and profit & loss statements, as well as branch-wise accounting for a holistic view of your organizations financial data.',
            img: 'assets/images/saas1/feature/financial.svg',
        },
        {
            title: 'Attendence',
            subTitle: '/HR/Pay Roll',
            description: 'ERP system covers employee data, leave, loan, and shift management, alongside advanced features for payroll and taxation, including gratuity management. It also supports time attendance through various methods, such as manual entry, bar code, and biometric devices.',
            img: 'assets/images/saas1/feature/human-resources.svg',
        },
        {
            title: 'Resource',
            subTitle: '& Planning ',
            description: ' Our ERP system offers resource management for human, material, and machinery, including scheduling and manufacturing planning. It provides order status tracking, planning reports, performance comparisons, and forecasting for departmental insights.',
            img: 'assets/images/saas1/feature/planning.svg',
        },
        {
            title: 'Merchandiser/Sales',
            subTitle: 'Managemant',
            description: ' Our ERP system includes inquiry and customer management, along with order status tracking and brand information. It also handles shipment planning, sales orders for production and samples, and efficient claim/discount management.',
            img: 'assets/images/saas1/feature/sales.svg',
        },
        {
            title: 'Manufacturing',
            subTitle: 'Production',
            description: 'Our ERP system encompasses materials and production management, including matching, cutting, fusing, lining, and stitching processes. It supports leather request, carton, and packing list management, along with order rate and job card tracking.',
            img: 'assets/images/saas1/feature/manufacturing.svg',
        },
        {
            title: 'Export',
            subTitle: 'Management',
            description: 'The ERP system covers vessel and commission agent data, as well as L/C opening and record-keeping, with clear agent and advance payment management. It handles shipping terms like DA, CAT, C&F, CIF, FOB, along with custom and bank invoices, shipper and insurance letters, and rebate processing.',
            img: 'assets/images/saas1/feature/export.svg',
        }
    ]
    // leather section end------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////
    // tannert section start----------------------------------------------------- -->
    Features = [
        {
            title: 'Integration:',
            subTitle: 'Managemant',
            description: ' ERP systems often integrate with other functional areas like finance and account, procurement, and sales. This integration ensures that manufacturing production aligns with broader organizational goals and strategies.  ERP software assists in planning the manufacturing process by determining what needs to be produced, in what quantities, and when. This involves creating production schedules, setting priorities, and optimizing resource allocation.',
            img: 'assets/images/saas1/feature/integration2.svg',
        },
        {
            title: 'Work Order',
            subTitle: 'Management',
            description: ' ERP system generates work orders for specific manufacturing tasks. It tracks the progress of each order, monitors resource usage, and provides real-time updates on production status',
            img: 'assets/images/saas1/feature/Work-Order.svg',
        },
        {
            title: 'Inventory',
            subTitle: 'Management',
            description: 'Manufacturing Production, in the context of providing an Enterprise Resource Planning (ERP) solution, refers to the processes and activities involved in the creation and assembly of physical products within a manufacturing facility. ERP systems are software solutions that help organizations manage and optimize various aspects of their operations, including manufacturing production',
            img: 'assets/images/saas1/feature/Inventory.svg',
        },
        {
            title: 'Production',
            subTitle: 'Managemant',
            description: 'Our ERP system manages leather data, including dye crust and consumption returns, with pickle under process tracking and lot management. It also handles supplier details, process information, and stock and purchase return adjustments.',
            img: 'assets/images/saas1/feature/sales.svg',
        },
        {
            title: 'Chemical, mechanical',
            subTitle: 'General Store',
            description: 'Enterprice resource planning (ERP) system covers chemical definition and supplier information, including request and purchase order management with gate pass tracking. It enables consumption lot-wise monitoring, stock ledger management, and trip card processing.',
            img: 'assets/images/saas1/feature/Chemical-Mechanica.svg',
        },
        {
            title: 'Machine and',
            subTitle: 'Resource Management:',
            description: 'ERP solutions help in scheduling and tracking the use of machinery, equipment, and human resources in the production process. This includes tracking machine downtime and maintenance schedules.',
            img: 'assets/images/saas1/feature/machine.svg',
        }
    ]
    // tannery section end------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////
    // workflow and build section start------------------------------------------ -->
    screenshots =
        [
            {
                img: "assets/images/saas1/Flow-chart.jpg",
                title: 'Leathers FlowChart'
            },
            {
                img: "assets/images/saas1/Flow-chart.jpg",
                title: 'Tannery FlowChart'
            },
            {
                img: "assets/images/saas1/Flow-chart.jpg",
                title: 'Leathers FlowChart'
            },
            {
                img: "assets/images/saas1/Flow-chart.jpg",
                title: 'Tannery FlowChart'
            },
        ]

    screenshotscarouselOptions = {
        items: 3,
        margin: 0,
        // autoHeight: true,
        nav: false,
        autoplay: false,
        center: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        dots: true,
        loop: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 3
            }
        }
    }
    // workflow and build section end-------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////
    // Dashboard section start--------------------------------------------------- -->
    public work: any = 'leather-garments'
    openWorkSlide(val) {
        this.work = val
    }
    nav = [
        {
            img: 'assets/images/saas1/tab-icon/leather.png',
            title: 'leather-garments'
        },
        {
            img: 'assets/images/saas1/tab-icon/accessories.png',
            title: 'accessories'
        },
        {
            img: 'assets/images/saas1/tab-icon/production.png',
            title: 'production'
        },
        {
            img: 'assets/images/saas1/tab-icon/chemical.png',
            title: 'chemical'
        },
        {
            img: 'assets/images/saas1/tab-icon/sales.png',
            title: 'sales'
        },
        {
            img: 'assets/images/saas1/tab-icon/export.png',
            title: 'exprot'
        }
    ]
    contentImg = [
        {
            img: 'assets/images/saas1/tab/leather.jpg',
            title: 'leather-garments'
        },
        {
            img: 'assets/images/saas1/tab/accessories.jpg',
            title: 'accessories'
        },
        {
            img: 'assets/images/saas1/tab/production.jpg',
            title: 'production'
        },
        {
            img: 'assets/images/saas1/tab/chemical.jpg',
            title: 'chemical'
        },
        {
            img: 'assets/images/saas1/tab/sales.jpg',
            title: 'sales'
        },
        {
            img: 'assets/images/saas1/tab/export.jpg',
            title: 'exprot'
        }
    ]
    // Dashboard section end----------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////
    // review section start------------------------------------------------------ -->
    modalService: NgbModal
    testimonialcarousel = [
        {
            quote: "assets/images/saas1/testimonail-dot.png",
            review: "Life before Company was very chaotic — we got a lot of phone calls, a lot of mistyped orders. So with Company, the ability to see the order directly from the customer makes it so streamlined.",
            userImg: "assets/images/saas1/brand/02.png",
            userName: "Vip Groups",
        },
        {
            quote: "assets/images/saas1/testimonail-dot.png",
            review: "Life before Company was very chaotic — we got a lot of phone calls, a lot of mistyped orders. So with Company, the ability to see the order directly from the customer makes it so streamlined.",
            userImg: "assets/images/saas1/brand/14.png",
            userName: "Noor Leather Garments",
        },
        {
            quote: "assets/images/saas1/testimonail-dot.png",
            review: "Life before Company was very chaotic — we got a lot of phone calls, a lot of mistyped orders. So with Company, the ability to see the order directly from the customer makes it so streamlined.",
            userImg: "assets/images/saas1/brand/03.png",
            userName: "Vilocity Textiles",
        }
    ]
    testimonialcarouselOptions = {
        items: 1,
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
    }
    openVerticallyCentered(content) {
        this.modalService.open(content, { centered: true, size: 'md' });
    }
    // review section end-------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////
    // demo start---------------------------------------------------------------- -->
    public formBuilder: any;
    public myForm: FormGroup;
    public submitted: boolean;
    async initializeForm() {
        this.myForm = this.FB.group({
            'id': [''],
            'requestType': ['', [Validators.required]],
            'requestByName': ['', [Validators.required]],
            'companyName': ['', [Validators.required]],
            'mobile': ['', [Validators.required]],
            'email': ['', [Validators.required]],
            'message': [''],
            'sourceurl': [''],
        })
    }
    async saving() {
        debugger
        if (!this.myForm.controls['id'].value) {
            this.myForm.controls['id'].setValue(0)
        }
        this.myForm.controls['sourceurl'].setValue('https://leathererp.com/')
        debugger
        if (!this.myForm.controls['requestType'].value) {
            this.myForm.controls['requestType'].setValue('Proposal Quotation')
        } else {
            this.myForm.controls['requestType'].setValue('Demo request')
        }
        await this.http.post(environment.apiURL + 'Customer_Request/save', this.myForm.value).toPromise().then((user: any) => {
            debugger
            if (user.id > 0) {
                this.myForm.controls['id'].setValue(user.id);
                alert(user.messages)
                this.clear();
            } else {
            }
        }).catch((error) => {

            if (error) {
                ;
            }
        });
    }
    async clear() {
        debugger
        this.myForm.controls[''];
        this.formBuilder = []
    }
    // demo end------------------------------------------------------------------ -->
    /////////////////////////////////////////////////////////////////////////////////
    // clients section start----------------------------------------------------- -->
    brands = [
        {
            img: "assets/images/saas1/brand/01.png"
        },
        {
            img: "assets/images/saas1/brand/02.png"
        },
        {
            img: "assets/images/saas1/brand/03.png"
        },
        {
            img: "assets/images/saas1/brand/04.png"
        },
        {
            img: "assets/images/saas1/brand/05.png"
        },
        {
            img: "assets/images/saas1/brand/06.png"
        },
        {
            img: "assets/images/saas1/brand/07.png"
        },
        {
            img: "assets/images/saas1/brand/08.png"
        },
        {
            img: "assets/images/saas1/brand/09.png"
        },
        {
            img: "assets/images/saas1/brand/10.png"
        },
        {
            img: "assets/images/saas1/brand/11.png"
        },
        {
            img: "assets/images/saas1/brand/12.png"
        },
        {
            img: "assets/images/saas1/brand/13.png"
        },
        {
            img: "assets/images/saas1/brand/14.png"
        },
        {
            img: "assets/images/saas1/brand/15.png"
        },
        {
            img: "assets/images/saas1/brand/16.png"
        },
        {
            img: "assets/images/saas1/brand/17.png"
        },
    ]
    brandCarouselOptions = {
        items: 5,
        margin: 40,
        autoHeight: true,
        nav: false,
        dots: false,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        responsive: {
            0: {
                items: 1,
                margin: 40
            },
            360: {
                items: 2,
                margin: 20
            },
            480: {
                items: 3,
                margin: 30
            },
            768: {
                items: 5,
                margin: 30
            },
            1366: {
                items: 6
            }
        }
    }
}
// clients section end------------------------------------------------------- -->
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
//<-- ----------------------------The End------------------------------------ -->