import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountingWebComponent } from './layouts/accounting-web/accounting-web.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo:  '',
    pathMatch: 'full',
  },
  {
    path: '',
    component:  AccountingWebComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
