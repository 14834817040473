// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { AccountingWebComponent } from './accounting-web.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from '../layouts-routing.module';
import { SwiperModule } from 'swiper/angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from 'ng-gallery';
import { AppComponent } from 'src/app/app.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VgApiService, VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';




@NgModule({
    imports: [ 
            CommonModule,
            LayoutsRoutingModule,
            SwiperModule,
            CarouselModule,
            NgbModule,
            GalleryModule,
            LightboxModule,
            SharedModule,
            FormsModule,
            ReactiveFormsModule,
            // Ng5SliderModule,
            NgxMasonryModule,
            FlexLayoutModule,
            MatToolbarModule,
            MatButtonModule,
             MatIconModule,
            MatMenuModule,
            MatSidenavModule,
            MatListModule,
            VgCoreModule,
            VgControlsModule,
            VgOverlayPlayModule,
            VgBufferingModule

    ],
    declarations: [
        AccountingWebComponent,
        // AppComponent
    ],
    exports: [
        AccountingWebComponent,
    ]
})
export class AccountingWebModule {
   
}
