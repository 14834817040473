<app-loader></app-loader>
<router-outlet>
  <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'>
  <header class="app2 header-fixed loding-header  position-fixed custom-scroll nav-lg" style="height: 80px;"
    [ngStyle]="{'background-color':'rgb(139 69 19 / 80%)'}">
    <div style="height: 150px;">
      <mat-toolbar style="align-items: start;">
        <a href="#" class="d-inline-block m-r-auto">
          <img src="assets/images/logo/bridge.png" alt="bridge" class="img-fluid"
            style="width: 130px; margin-bottom: 50px;" height=130px;>
        </a>
        <div fxShow="true" fxHide.lt-md="fales">
          <a (click)="action('Leather-ERP')" mat-button style="color: white;">Leather ERP</a>
          <a (click)="action('About')" mat-button style="color: white;">About</a>
          <a (click)="action('Leather')" mat-button style="color: white;">Leather</a>
          <a (click)="action('Tannery')" mat-button style="color: white;">Tannery</a>
          <a (click)="action('Dashboard')" mat-button style="color: white;">Dashboard</a>
          <a (click)="action('Worlflow')" mat-button style="color: white;">Worlflow</a>
          <a (click)="action('Review')" mat-button style="color: white;">Review</a>
          <a (click)="action('DEMO')" mat-button style="color: white;">DEMO</a>
          <a (click)="action('FAQ')" mat-button style="color: white;">FAQ</a>
          <a (click)="action('Contact')" mat-button style="color: white;">Contact</a>
        </div>
        <div fxShow="fales" fxHide.gt-sm="true">
          <a (click)="sidenav.toggle()"><i class="bx bx-menu" id="menu"
              style="color: white; font-size: 30px; font-weight: 500;"></i></a>
        </div>
      </mat-toolbar>
      <mat-sidenav-container fxFlexFill class="example-container">
        <mat-sidenav #sidenav fxLayout="column" style="width: 35%; height: 50%; overflow: auto; margin-left: 225px;">
          <div fxLayout="column" style="background-color: #8B4513; opacity: 0.9;">
            <a (click)="action('Leather-ERP')" mat-button style="color: white;">Leather-ERP</a>
            <a (click)="action('About')" mat-button style="color: white;">About</a>
            <a (click)="action('Leather')" mat-button style="color: white;">Leather</a>
            <a (click)="action('Tannery')" mat-button style="color: white;">Tannery</a>
            <a (click)="action('Dashboard')" mat-button style="color: white;">Dashboard</a>
            <a (click)="action('Worlflow')" mat-button style="color: white;">Worlflow</a>
            <a (click)="action('Review')" mat-button style="color: white;">Review</a>
            <a (click)="action('DEMO')" mat-button style="color: white;">DEMO</a>
            <a (click)="action('FAQ')" mat-button style="color: white;">FAQ</a>
            <a (click)="action('Contact')" mat-button style="color: white;">Contact</a>
          </div>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </header>
</router-outlet>
<div class="theme-pannel-main" *ngIf="url!='/landing'">
  <ul>
    <li class="sidebar-btn dark-light-btn" *ngIf="customize.data.settings.layout_version == 'dark'"
      (click)="customizeLayoutVersion('light')">
      <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version">Light</span></a>
    </li>
  </ul>
</div>