<!--component html goes here -->
<!-- navbar end------------------------------------------------------------------ -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- header section Start-------------------------------------------------------- -->
<div class id="Leather-ERP">
    <section class="saas1 header" id="home" routerLink="/LEATHER-ERP" path="/LEATHER-ERP">
        <div class="saas1-header bg header8-content"
            [ngStyle]="{'background-image': 'url(assets/images/saas1/slider-banner3.jpg)'}">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="center-text">
                            <div>
                                <div class="header-text">
                                    <div class="d-flex">
                                        <h1><span style="color: #8B4513;">L</span>eather <span class="small-text">ERP
                                            </span><span style="color: #8B4513;">S</span>oftware</h1>
                                        <div class="center-content slider-logo">
                                            <img src="assets/images/saas1/slider-logo.png" class="img-fluid"
                                                alt="slider-logo">
                                        </div>
                                    </div>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="text-white">"Development by <a href="https://bizsoft.pk/tannery.html"
                                            target="_blank" id="bizsoft">Bizsoft:</a> The Comprehensive <span>Leather
                                            ERP
                                            Software </span> Solution".
                                    </h3>
                                </div>
                                <div class="link-horizontal">
                                    <ul>
                                        <li>
                                            <a href="" class="btn btn-default primary-btn transparent"
                                                style="padding-left: 50px; padding-right:50px;"> Explore <img
                                                    src="assets/images/saas1/video-icon.png" alt="video-icon"
                                                    class="m-l-5">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="center-text slider-banner">
                            <img src="assets/images/saas1/export.jpg" alt="export sell"
                                style="width: 600px; height: 650px; margin-left: 70px; background-position: center; background-size: cover; object-fit: cover; background-repeat: no-repeat; background-clip: content-box; ">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/images/saas1/background2.png" class="img-fluid set-abs background" alt="Leather ERP">
        <img src="assets/images/saas1/dot.png" class="img-fluid set-abs dot" alt="dot">
    </section>
</div>
<style>
    #bizsoft {
        text-decoration: none;
        font-size: 31px;
        font-weight: 300;
        color: #fff;
        cursor: pointer;

    }

    #bizsoft:hover {
        color: rgb(12, 161, 211);
    }
</style>
<!-- header section end---------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- about section Start--------------------------------------------------------- -->
<div class id="About">
    <section class="gym format" id="about">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="text-center center-content">
                        <div class="about-img">
                            <div class="borders-around">
                                <div>
                                    <div class="bg-black"></div>
                                </div>
                                <img src="assets/images/saas1/bizsoft.jpg" alt="bizsoft" class="img-fluid format-img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="center-text">
                        <div>
                            <div class="format-small-text">
                                <h6 class="font-primary borders-before"><span style="color: #8B4513;">our
                                        progress</span>
                                </h6>
                            </div>
                            <div class="format-head-text">
                                <h3 class="about-font-header gradient-text" style="color: #8B4513;">about us</h3>
                            </div>
                            <div class="format-sub-text">
                                <p class="p-light about-para">Bizsoft specializes in delivering high-quality custom ERP
                                    solutions, with expertise in Bridge ERP systems for various industries, including
                                    leather and textile. We prioritize timely implementations, drawing on a decade of
                                    experience with a remarkable 94% success rate. Our services encompass merchandise
                                    management, manufacturing, production systems, and efficient inventory management.
                                </p>
                                <p class="p-light about-para">For tanneries, Bizsoft provides tailored ERP software and
                                    chemical inventory solutions designed to streamline complex leather production
                                    processes. Our systems offer precise production data, facilitating effective
                                    planning and management. Features include leather lot management, cost analysis,
                                    payroll administration, and seamless departmental integration for comprehensive
                                    end-to-end tracking within the intricate tannery environment. This specialized
                                    solution is an integral part of our ERP management system. Your future begins with
                                    Bizsoft today.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- about section end----------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- leather feature section start----------------------------------------------- -->
<div class id="Leather">
    <section id="feature" class="saas1 service">
        <div class="about-chat">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center"
                        [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-3.png)'}"
                        style="background-position: center; background-size: cover; object-fit: cover; height: 300px;">
                        <div class="title">
                            <img src="assets/images/saas1/title-logo.png" alt="title-logo leather garments"
                                class="img-fluid" style="width: 55px; height: 25px;">
                            <div class="main-title">
                                <h2 style="color: white; padding-top: 30px;">
                                    Leather Garment
                                </h2>
                            </div>
                            <hr>
                            <div class="sub-title">
                                <p class="p-padding" style="color: white; padding-top: 30px; column-count: auto;">The
                                    leather garments industry has evolved with a focus on systematic operations, leveraging
                                    (ERP) software for efficient resource planning, data management, and enhanced
                                    manufacturing quality. Bizsoft specializes in delivering
                                    custom ERP solutions with a 94% success rate.</p>
                                <!-- <button type="button" (click)="showText()" style="float: right; font-size: 10px;">
                                    {{ isReadMore ? 'Read More': 'Read Less' }} </button> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of features"
                        style="padding-top: 30px;">
                        <div class="chat-box">
                            <img [src]="data.img" alt="stay-connected" style="width: 50px; height: auto;">
                            <div class="feature-content">
                                <h4 class="service-heading"> {{data.title}} <span
                                        class="theme-color">{{data.subTitle}}</span>
                                </h4>
                                <hr>
                                <p>{{data.description}}</p>
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li class="">
                            <a href="" class="btn btn-default primary-btn1 transparent"
                                style="color: black; padding-left: 30px; padding-right: 30px;"> Download Brochure
                                <img src="assets/images/saas1/video-icon.png" alt="video-icon" class="m-l-5">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- <style>
    .limitTextHeight {
        height: 58px;
        overflow: hidden;
    }
</style> -->
<!-- end feature section--------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- tannery feather section start----------------------------------------------- -->
<div class id="Tannery">
    <section id="feature" class="saas1 service">
        <div class="about-chat">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center"
                        [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-3.png)'}"
                        style="background-position: center; background-size: cover; object-fit: cover; height: 300px;">
                        <div class="title">
                            <img src="assets/images/saas1/title-logo.png" alt="title-logo leather tannery"
                                class="img-fluid" style="width: 55px; height: 25px;">
                            <div class="main-title">
                                <h2 style="color: white; padding-top: 30px;">
                                    Leather Tannery
                                </h2>
                            </div>
                            <hr>
                            <div class="sub-title">
                                <p class="p-padding" style="color: white; padding-top: 40px;">
                                    Bizsoft offers business tannery ERP software specifically designed to regulate the
                                    most diverse in the Tannery management production, delivering accurate information
                                    on the main production indicators of a tannery and easy and effective planning
                                    tools. It is a component of the ERP management system.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of Features"
                        style="padding-top: 30px;">
                        <div class="chat-box">
                            <img [src]="data.img" alt="stay-connected" style="width: 50px; height: auto;">
                            <div class="feature-content">
                                <h4 class="service-heading"> {{data.title}} <span
                                        class="theme-color">{{data.subTitle}}</span>
                                </h4>
                                <hr>
                                <p>{{data.description}}</p>
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li class="">
                            <a href="" class="btn btn-default primary-btn1 transparent"
                                style="color: black; padding-left: 30px; padding-right: 30px;"> Download Brochure
                                <img src="assets/images/saas1/video-icon.png" alt="video-icon" class="m-l-5">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- End tannery feather section------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- work or Dashboard section Start--------------------------------------------- -->
<div class id="Dashboard">
    <section id="how-work" class="saas1 howitwork">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="title">
                        <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"
                            style="width: 55px; height: 25px;">
                        <div class="main-title">
                            <h2>
                                Dashboards<span class="theme-color"></span><span class="theme-color"></span>
                            </h2>
                        </div>
                        <hr>
                        <div class="sub-title">
                            <p class="p-padding">Guide to setup and configuration. You can present below a guide and a
                                description of how your system configuration works and add some animated screens.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 work-tab">
                    <ul class="nav nav-pills justify-content-center " id="pills-tab" role="tablist">
                        <li class="nav-item  text-center" *ngFor="let data of nav">
                            <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work == data.title }"
                                (click)="openWorkSlide(data.title)">
                                <img [src]="data.img" alt="Dashboards icon">
                                <h6>{{data.title}}</h6>
                                <span></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="work-tab-bg work-content p-t-50">
            <div class="tab-content text-center" id="pills-tabContent">
                <div class="tab-pane fade show active" *ngFor="let data of contentImg"
                    [ngClass]="{'active show': work == data.title }" id="pills-work1" role="tabpanel">
                    <img [src]="data.img" alt="ERP dashboard" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
</div>
<!-- end work or Dashboard section----------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- build OR Worlflow section start--------------------------------------------- -->
<div class="" id="Worlflow">
    <section class="saas2 laptop-slider bg-gradient" id="screenshot"
        [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-3.png)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="title">
                        <div class="main-title">
                            <h2 class="text-white">WorkFlow</h2>
                        </div>
                        <div class="sub-title">
                            <p class="sub-title-para text-white">We believe we have created the most efficient SaaS
                                landing
                                page for
                                your users.
                                Landing page with features that will convince you to use it for your SaaS business.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="slider-container">
                <owl-carousel-o [options]="screenshotscarouselOptions" class="trainers-slider">
                    <ng-container *ngFor="let screenshot of screenshots">
                        <ng-template carouselSlide class="item">
                            <img [src]="screenshot.img" alt="ERP Dashboard" class="img-fluid">
                            <h5 class="post text-center "
                                style="color: black; margin: 20px; font-size: 20px; font-weight: 900; -webkit-text-stroke: 0.5px #fff;">
                                {{screenshot.title}}</h5>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </section>
</div>
<!-- end build or Worlflow section----------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- Review section start-------------------------------------------------------- -->
<div class id="Review">
    <section class="saas1 testimonial videos testimonial-bg">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="title">
                        <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"
                            style="width: 55px; height: 25px;">
                        <div class="main-title">
                            <h2> Clint Review
                            </h2>
                        </div>
                        <hr>
                        <div class="sub-title">
                            <p class="p-padding">Life before Company was very chaotic — we got a lot of phone calls, a
                                lot
                                of mistyped orders. So with Company, the ability to see the order directly from the
                                customer
                                makes it so streamlined.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="center-content">
                        <div class="video w-100">
                            <a class="button center-content">
                                <video width="450" height="" controls muted loop plays-imline>
                                    <source src="assets/images/saas1/testimonial-video-icon1.mp4" type="video/mp4"
                                        (click)="openVerticallyCentered(content)" alt="video" class="img-fluid">
                                </video>
                                <ng-template #content let-modal>
                                    <div class="modal-body video-modal">
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </ng-template>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="testimonial-slider">
                        <owl-carousel-o [options]="testimonialcarouselOptions" class="testimonial-carousel ">
                            <ng-container *ngFor="let slide of testimonialcarousel">
                                <ng-template carouselSlide>
                                    <div class="testimonial">
                                        <div class="testimonial-box">
                                            <img [src]="slide.quote" alt="dot">
                                            <h6>{{slide.review}}</h6>
                                        </div>
                                    </div>
                                    <div class="testi-profile">
                                        <div class="media">
                                            <img class="clint-logo" [src]="slide.userImg" alt="profile-testimonial">
                                            <div class="media-body">
                                                <h5 class="mt-0">{{slide.userName}}</h5>
                                                <h6>{{slide.position}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<style>
    .clint-logo {
        height: 93px;
        width: 76px;
        background-position: center;
        background-size: cover;
    }
</style>
<!-- Review section end---------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- form demo start------------------------------------------------------------- -->
<section class="app2 about format" id="DEMO">
    <div class="container">
        <div class="col-md-10 offset-md-1">
            <div class="title title2">
                <h6 class="font-primary borders main-text text-uppercase"><span style="color:#8b4513;">Demo &
                        Proposals</span></h6>
                <div class="sub-title">
                    <h2 class="title-text text-capitalize text-center"
                        style="font-size: 25px; font-weight: 500; letter-spacing: 1px;">Demo Request</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 counters set-height">
                <img src="assets/images/saas1/demo.png" alt="" class="img-fluid demomovingimg" style="width: auto; height: 400px;">
            </div>
            <div class="col-sm-7 counters">
                <div class="abouts  center-text">
                    <div>
                        <div class="row"
                            style="box-shadow:0 5px 35px rgba(139, 69, 19, 15); padding: 50px; border-radius: 20px;">
                            <div class="typography-box">
                                <div class="typo-content">
                                    <form [formGroup]="myForm" (ngSubmit)="saving()">
                                        <div class="form-row">
                                            <div class="col-12 mb-2">
                                                <input type="radio" name="RequestType" id="1" value="Proposal Quotation"
                                                    formControlName="requestType">
                                                <label for="one"> &nbsp;&nbsp;Proposal Quotation</label>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <input type="radio" name="RequestType" id="2" value="Demo request"
                                                    formControlName="requestType">
                                                <label for="two"> &nbsp;&nbsp;Demo request</label>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="requestByName"
                                                    placeholder="Enter Your name" required="true" type="text"
                                                    formControlName="requestByName">
                                            </div>
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="companyName"
                                                    placeholder="Enter Company name" required="true" type="text"
                                                    formControlName="companyName">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="mobile"
                                                    placeholder="Enter Mobile Number" required="true" type="number"
                                                    formControlName="mobile">
                                            </div>
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="email"
                                                    placeholder="Your Email" required="true" type="email"
                                                    formControlName="email">
                                            </div>
                                            <div class="col-12 mb-2">
                                                <textarea class="form-control" placeholder="Write Your Message"
                                                    id="message" rows="5" formControlName="message"
                                                    type="text"></textarea>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <a class="btn btn-default btn-gradient m-t-50" style="background-image: linear-gradient(to right, #071828, #8b4513, #8b4513, #071828);" (click)="saving()">Submit</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Demo end-------------------------------------------------------------------- -->
<!-- //////////////////////////////////////////////////////////////////////////// -->
<!-- faq section start----------------------------------------------------------- -->
<div class id="FAQ">
    <section id="faq" class="saas1 faq testimonial-bg"
        [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-3.png)'}"
        style="background-position: center; background-size: cover; object-fit: cover;">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="faq-block">
                        <div>
                            <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"
                                style="float: left; margin-top: 20px; margin-right: 10px; width: 55px; height: 25px;">
                            <h3 class="frequent-text" style="color: white;">
                                Frequently
                                Asked
                                Questions
                            </h3>
                            <h6 style="color: white;">
                                Our users are impatient. They're probably distracted too. Keep it simple and
                                beautiful,fun and functional. Clean aesthetics supported by a strong concept is what we
                                stand for.</h6>
                            <div class="accordion faq" id="accordionExample">
                                <div class="card">
                                    <ngb-accordion [closeOthers]="true" activeIds="static">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate" style="color: #ffffff;"></div>
                                                </a>
                                                <p style="color: white;">
                                                    What Are Some of the Benefits of ERP Software?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p style="color: white;">
                                                    Enterprise Resource Planning (ERP) software offers benefits such as
                                                    improved operational efficiency, streamlined processes, and enhanced
                                                    data visibility, aiding businesses in making informed decisions,
                                                    reducing costs, and increasing overall productivity.
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate" style="color: #ffffff;"></div>
                                                </a>
                                                <p style="color: white;">
                                                    What Are the Steps to Successful ERP Implementation?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p style="color: white;">
                                                    <li>
                                                        Planning: Begin with thorough planning, outlining objectives,
                                                        timelines, and resources.
                                                    </li>
                                                    <li>
                                                        Vendor Selection: Carefully choose a reputable ERP vendor that
                                                        aligns with your &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;business needs.
                                                    </li>
                                                    <li>
                                                        Training and Testing: Invest in comprehensive user training and
                                                        rigorous testing to &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ensure a smooth ERP
                                                        implementation process.
                                                    </li>
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate" style="color: #ffffff;"></div>
                                                </a>
                                                <p style="color: white;">
                                                    How Much Does an ERP Implementation Cost?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p style="color: white;">
                                                    No one cares about products. People care about ideas. Is a product
                                                    an
                                                    idea?
                                                    Noup. Is a brand? A good one is. No one cares about products. People
                                                    care
                                                    about ideas. Is a product an idea?
                                                    Noup. Is a brand? A good one is.
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate" style="color: #ffffff;"></div>
                                                </a>
                                                <p style="color: white;">
                                                    What Are ERP Implementation Statistics?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p style="color: white;">
                                                    ERP implementation statistics provide insights into the success
                                                    rates of ERP projects. On average, approximately 70% of ERP
                                                    implementations are considered successful. However, it's crucial to
                                                    note that the remaining 30% face challenges like budget overruns,
                                                    delays, or not achieving the intended benefits.
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-5">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate" style="color: #ffffff;"></div>
                                                </a>
                                                <p style="color: white;">
                                                    How Do You Plan an ERP Implementation?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p style="color: white;">
                                                    <li>
                                                        Define Objectives: Clearly outline your business goals and
                                                        desired outcomes from the
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ERP system.
                                                    </li>
                                                    <li>
                                                        Assess Current Processes: Analyze existing workflows and data to
                                                        identify areas for &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;improvement.
                                                    </li>
                                                    <li>
                                                        Create a Roadmap: Develop a detailed plan, including budget,
                                                        timeline, and resource &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;allocation,
                                                        and involve key stakeholders for successful ERP implementation.
                                                    </li>
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div>
                            <h6 class="link" style="color: white;">Still have a question? Reach out to us:
                                <a style="color: #8B4513;">info@bizsoft.pk</a>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="faq-img-block">
                        <img src="assets/images/saas1/faq-image.png" class="img-fluid" alt="faq-person">
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- end faq section------------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- client brand section Start-------------------------------------------------- -->
<section class="wedding saas1 brand-sliders">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title">
                    <div class="main-title">
                        <h2 class="gradien-tex" style="color: #8B4513;">Our Trust</h2>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="brandCarouselOptions" class="brand-slider">
                    <ng-container *ngFor="let brand of brands">
                        <ng-template carouselSlide class="item">
                            <a href="javascript:void(0)">
                                <img [src]="brand.img" alt="" class="img-fluid wedding-brand">
                            </a>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
        <div>
        </div>
    </div>
</section>
<!--client brand section end----------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!--footer start----------------------------------------------------------------- -->
<div class id="Contact">
    <footer class="saas1 footer2" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-3.png)'}"
        style="background-position: center; background-size: cover; object-fit: cover;">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="logo-sec">
                        <div class="footer-title mobile-title">
                            <h3 class="text-white">About Us</h3>
                        </div>
                        <div class="footer-contant">
                            <img src="assets/images/logo/bridge.png" alt="bridge" class="img-fluid footer-logo"
                                style="width: 150px; object-fit: cover;">
                            <div class="footer-para">
                                <h6 class="text-white para-address">Bridge</h6>
                                <h6 class="text-white para-address">BIZSOFT IS AN INDEX GREAT LEVELER AS A SOFTWARE
                                    HOUSE IN PAKISTAN</h6>
                            </div>
                            <ul class="d-d-flex footer-social social">
                                <li class="footer-social-list">
                                    <a href="https://pk.linkedin.com/company/bizsoft-solutions-pvt-limited"
                                        target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li class="footer-social-list">
                                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FPvtBizsoft"
                                        target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li class="footer-social-list">
                                    <a href="https://pk.linkedin.com/company/bizsoft-solutions-pvt-limited"
                                        target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                                <li class="footer-social-list">
                                    <a href="https://www.youtube.com/watch?v=a0tKAvNQ0O4" target="_blank"><i
                                            class="fa fa-youtube" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Help links</h3>
                    </div>
                    <div class="footer-contant">
                        <h5 class="footer-headings">Help links</h5>
                        <div>
                            <ul class="footer-lists">
                                <li class=""><a href="https://bizsoft.pk/" target="_blank">Bizsoft Solution Private
                                        Limited </a></li>
                                <li class=""><a href="https://bizsoft.pk/about.html" target="_blank">About</a></li>
                                <li class=""><a href="https://bizsoft.pk/erpsolutions.html" target="_blank">Enterprice
                                        Resource Planning(ERP)</a></li>
                                <li class=""><a href="https://bizsoft.pk/solutions.html" target="_blank">Solution</a>
                                </li>
                                <li class=""><a href="https://bizsoft.pk/contact.html" target="_blank">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Contact Info</h3>
                    </div>
                    <div class="footer-contant">
                        <h5 class="footer-headings">Contact Info</h5>
                        <div>
                            <ul class="footer-lists contacts">
                                <li>
                                    <i aria-hidden="true" class="fa fa-map-marker"></i>
                                    Anum Blessing, Suit No. 723, 7th Floor, Plot Zee KCHS Block 7 & 8 Opposite Old Duty
                                    Free Shop, Main Shahrah-E-Faisal, Karachi, Pakistan.
                                </li>
                                <li>
                                    <i aria-hidden="true" class="fa fa-phone m-r-15"></i>+92-213-453-7211
                                    |Or Whatsapp +92-301-8222-487
                                </li>
                                <li><i aria-hidden="true" class="fa fa-envelope m-r-15"></i>Info@Bizsoft.Pk
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
<app-tap-to-top></app-tap-to-top>
<!-- Footer end------------------------------------------------------------------ -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!--copyright start-------------------------------------------------------------- -->
<div class="saas1 copyright" style="background-color: #8B4513;">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="link-horizontal center-text">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-8">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright © 2023, Development By BizSoft
                        Solutions (Pvt) Limited. All Rights Reserved.</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--copyright end---------------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- ---------------------------------The End------------------------------------ -->